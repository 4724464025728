import React, { Component } from "react";

class StocksSelection extends Component {
    render() {
        return (
            <div>
                selection
            </div>
        );
    }
}

export default StocksSelection;
