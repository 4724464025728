import React, { Component } from "react";
import Modal from 'react-bootstrap/Modal';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import Button from 'react-bootstrap/Button';

import axios from "axios";
import Form from "react-bootstrap/Form";

class DeleteAccountModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            demoUser: false
        };
        this.deleteAccount = this.deleteAccount.bind(this);
        this.handleCloseDeleteAccountModal = this.handleCloseDeleteAccountModal.bind(this);
    }

    deleteAccount = async () => {
        let config = {
            headers: {
                authorization: localStorage.getItem('user')
            }
        };

        await axios
            .delete("/registration/", config)
            .then(() => this.props.closeDeleteAccountModal())
            .then(() => this.props.clearUser())
            .catch((err) => this.handleDeleteAccountError(err));
    };

    handleDeleteAccountError = (err) => {
        console.log(err);
        if (err.message === "Request failed with status code 401") {
            this.setState({demoUser: true});
        }
    };

    handleCloseDeleteAccountModal = () => {
        this.setState({demoUser: false});
        this.props.closeDeleteAccountModal();
    };

    render() {
        let demoUserMessage = null;
        if (this.state.demoUser) {
            demoUserMessage = (
                <div>
                    <p id={"demo-user-warning"}>Demo users cannot delete themselves.</p>
                </div>
            );
        }

        return (
            <Modal
                animation={false}
                show={this.props.showDeleteAccountModal}
                onHide={this.handleCloseDeleteAccountModal}
            >
                <Modal.Header className={"modal-header-delete"} closeButton={true} closeLabel={''} closeVariant={'white'}>
                    <Modal.Title>Delete Account</Modal.Title>
                </Modal.Header>
                <div className={'delete-modal'}>
                    <Modal.Body>
                        Are you sure you want to delete this account?
                        {demoUserMessage}
                    </Modal.Body>
                </div>
                <Modal.Footer className={"modal-footer"}>
                    <Button id={'delete-account-btn'} variant="danger" onClick={this.deleteAccount}>
                        Delete
                    </Button>
                    <Button variant="secondary" onClick={this.handleCloseDeleteAccountModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default DeleteAccountModal;
